.otp::placeholder {
  color: #d0d5dd;
}
ul::-webkit-scrollbar {
  width: 4px;
}

ul::-webkit-scrollbar-thumb {
  width: 1px;
  -webkit-box-shadow: inset 0 0 6px #d0d5dd;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiPaper-root {
  box-shadow: none !important;
}
