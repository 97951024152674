/* Custom styles for the multi-date-picker component */
.primary .rmdp-header .rmdp-arrow {
  border-color: #667085 !important; /* Change the arrow color */
}

/* Optional: Customize other calendar elements (e.g., dates, hover states) */
.primary .rmdp-day {
  color: #344054;
}
.primary .rmdp-week-day {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
}
.primary .rmdp-header-values {
  color: #101828;
  font-weight: 500;
  font-size: 18px;
}

.primary .rmdp-arrow-container:hover {
  background-color: transparent;
  box-shadow: none;
}
.primary .rmdp-day.rmdp-today span {
  background-color: #eaecf0;
  color: #344054;
}
.primary .rmdp-range {
  background-color: #0c425f;
  color: #ffffff;
}
